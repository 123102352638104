@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-DemiIt.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-DemiIt.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-UltLtIt.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-UltLtIt.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-Medium.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-It.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-It.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-HeavyIt.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-HeavyIt.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-MediumIt.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-MediumIt.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-BoldIt.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-BoldIt.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-UltLt.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-UltLt.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-Demi.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-Demi.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-Heavy.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AvenirNextW10';
  src: url('./assets/fonts/AvenirNextW10-Heavy.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextW10-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AvenirNextW10';
  src: url('./assets/fonts/AvenirNextW10-Medium.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextW10-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AvenirNextLTW01';
  src: url('./assets/fonts/AvenirNextLTW01-Regular.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTW01-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AvenirNextW04';
  src: url('./assets/fonts/AvenirNextW04-Bold.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextW04-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AvenirNextW10-Demi';
  src: url('./assets/fonts/AvenirNextW10-Demi.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextW10-Demi.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
