@import './fonts';

.product-content {
  background: transparent;
}
html {
  font-size: 12pt;
}

#root {
  padding-top: 0;
}

#footer {
  background: $gray-200;
  min-height: 20vh;
}

.masonry .maki-container {
  align-items: flex-start;
}

.maki.card .card-body {
  padding: $card-spacer-x;

  .card-title {
    @extend .text-truncate;
    .title {
      @extend .text-truncate;
      font-weight: 900;
    }
  }
}

h1,
h2,
.h1,
.h2 {
  font-weight: 900;
}

#main-menu {
  background: transparent;

  .nav-link {
    background-color: $black; //$light-milk;
    color: $white;
    margin-bottom: 0.4rem;
    font-weight: 900;
    text-transform: uppercase;
  }
}

.secondary-menu .menu {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

#header > .navbar {
  height: $calculated-navbar-height-sm;
  font-size: 16px;
  @include media-breakpoint-up(md) {
    height: $calculated-navbar-height;
    font-size: 22px;
  }

  .navbar-brand {
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    }
  }
}

.offcanvas-collapse {
  top: $calculated-navbar-height + 1rem;
  @include media-breakpoint-down(sm) {
    top: $calculated-navbar-height-sm + 1rem;
  }
}

video {
  background: $dark;
}

#cart-menu,
#main-menu {
  font-size: 16px;
}
#main-menu {
  bottom: auto;
  overflow: visible;
}

.logo-container {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $black;
  pointer-events: none;
}
.logo-name {
  // font-size: 18px;
  @include media-breakpoint-up(md) {
    font-size: 40px;
  }
  font-weight: 900;
  font-style: normal;
}
.logo-branch {
  @extend .d-none;
  @extend .d-md-inline;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  color: rgba(0, 0, 0, 0.5);
  position: relative;
  padding-left: 30px;

  &::before {
    position: absolute;
    content: '';
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 35px;
    background-color: currentColor;
  }
}
.product-listing {
  background: $info;
}

.product-detail-title {
  font-weight: 900;
  font-style: normal;
  font-size: 40px;
  @include media-breakpoint-up(md) {
    font-size: 55px;
  }
  position: relative;
  margin-top: 0;
  margin-bottom: map-get($spacers, 4);

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: map-get($spacers, 2) * -1;
    width: 85px;
    height: 3px;
    background-color: $black;
    transform: translateX(-50%);

    @include media-breakpoint-up(md) {
      left: 0;
      width: 200px;
      transform: translateX(0);
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

//
.customer-nav-row {
  row-gap: 0.5rem;
}

.center-on-mobile {
  @include media-breakpoint-down(xs) {
    text-align: center;
    justify-content: center !important;
    margin-bottom: 1rem;
  }
}
